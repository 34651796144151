export const COSMIC_BASE_URL = 'https://api.cosmicjs.com/v3/buckets/tulumparty-production/objects?read_key=7pMG22sp4oEfuIRswb3c9kfsxlfxa9vxrGAOUBJttBwiP4FkGQ';
export const MEDIA_BASE_URL = 'https://api.cosmicjs.com/v3/buckets/tulumparty-production/media';
export const CABO_BASE_URL =
  'https://api.cosmicjs.com/v3/buckets/tulumparty-cabo/objects?read_key=YDn0yNZsHaT54IO4Uk6q4uVeiWxu0yzonz0lUo1eK91g4W8j0F';
const LIMIT = 16;
export const PARTY_LIMIT = 25;
const FAQ_LIMIT = 40;
const FAQ_CLUB_LIMIT = 3;
const COMMUNITY_LIMIT = 5;
const ALL_PARTY_LIMIT = 55;
const MINI_PARTIES_LIMIT = 65;
const DJ_SCHEDULE = 150;
const BRANDSLIMIT = 16;
let metadataFieldsParty = [
  'metadata.extra_content',
  'metadata.caption',
  'metadata.video.content',
  'metadata.video.metadata',
  'metadata.date',
  // 'metadata.brand.title',
  // 'metadata.brand.slug',
  'metadata.start_time',
  'metadata.tickets_link',
  'metadata.reservations_link',
  'metadata.event_link',
  'metadata.main_image',
  'metadata.venue.title',
  'metadata.venue.metadata.address',
  'metadata.venue.slug'
].join(",");
let metadataFieldsParties = [
  'metadata.extra_content',
  'metadata.caption',
  'metadata.video.content',
  'metadata.video.metadata',
  'metadata.date',
  // 'metadata.brand.title',
  // 'metadata.brand.slug',
  'metadata.tickets_link',
  'metadata.reservations_link',
  'metadata.lineup',
  'metadata.start_time',
  'metadata.main_image',
  // 'metadata.venue.title',
  // 'metadata.venue.metadata.address',
  // 'metadata.venue.slug'
].join(",");
let metadataFieldsPartiesIndex = [
  'metadata.extra_content',
  'metadata.caption',
  // 'metadata.video.content',
  // 'metadata.video.metadata',
  'metadata.date',
  // 'metadata.brand.title',
  // 'metadata.brand.slug',
  'metadata.tickets_link',
  'metadata.reservations_link',
  'metadata.lineup',
  'metadata.start_time',
  'metadata.main_image',
  // 'metadata.venue.title',
  // 'metadata.venue.metadata.address',
  // 'metadata.venue.slug'
].join(",");
let metadataFieldsAllParty = [
  'metadata.caption',
  'metadata.main_image',
  'metadata.date',
  'metadata.start_time',
].join(",");
let metadataFieldsArtist = [
  'metadata.genere',
  'metadata.headline',
  'metadata.spotify_listeners',
  'metadata.spotify_id',
  'metadata.videoid',
  'metadata.videotitle',
  'metadata.videocaption',
  'metadata.main_image',
  'metadata.soundcloud',
  'metadata.short',
  'metadata.flag',
  'metadata.parties.slug',
  'metadata.parties.title',
  'metadata.parties.metadata.main_image',
  'metadata.parties.metadata.start_time',
  'metadata.parties.metadata.venue.slug',
  'metadata.parties.metadata.venue.title',
  'metadata.parties.metadata.date',
  'metadata.instagram',
  'metadata.headline',].join(",");
let metadataFieldsFestival = [
  'metadata.parties.slug',
  'metadata.parties.title',
  // 'metadata.parties.metadata.brand.title',
  // 'metadata.parties.metadata.brand.slug',
  'metadata.parties.metadata.main_image',
  'metadata.parties.metadata.lineup',
  'metadata.parties.metadata.start_time',
  // 'metadata.parties.metadata.venue.slug',
  // 'metadata.parties.metadata.venue.title',
  'metadata.parties.metadata.date',
].join(",");
let metadataFieldsBrand = [
  'metadata.main_image',
  'metadata.logo',
  'metadata.instagram_profile',
  'metadata.past_parties.slug',
  'metadata.past_parties.title',
  'metadata.past_parties.metadata.main_image',
  'metadata.past_parties.metadata.lineup',
  'metadata.past_parties.metadata.start_time',
  'metadata.past_parties.metadata.venue.slug',
  'metadata.past_parties.metadata.venue.title',
  'metadata.past_parties.metadata.date',
].join(",");
let metadataFieldsBrandIndex = [
  'metadata.main_image',
  'metadata.description',
  'metadata.logo',

].join(",");

let ONE_DAY = 86400000;
let PARTY_START_RANGE = new Date(Date.now() - ONE_DAY)
  .toISOString()
  .slice(0, 10);

let SCHEDULE_START_RANGE = new Date(Date.now())
  .toISOString()
  .slice(0, 10);


const getParty = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=title,slug,content,${metadataFieldsParty}&query={"type":"parties","slug":"${slug}"}`
    )
  ).then((response) => response.json());
};

const getCaboParty = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=title,slug,content,${metadataFieldsParty}&query={"type":"san-jose-cabo-party","slug":"${slug}"}`
    )
  ).then((response) => response.json());
};

const getIslaParty = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=title,slug,content,${metadataFieldsParty}&query={"type":"isla-parties","slug":"${slug}"}`
    )
  ).then((response) => response.json());
};

function getMiniParties(args) {
  let request = `${COSMIC_BASE_URL}&limit=${MINI_PARTIES_LIMIT}&sort=metadata.date&props=title,slug,content,metadata&query={
      "type":"miniparties",
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"}`
  if (args) {
    request += `,"slug": { "$regex": "${args}", "$options": "i"}}`
    return fetch(
      encodeURI(request) ).then((response) => response.json());
  } else {
    return fetch( encodeURI(request+"}")).then((response) => response.json())
  }
}


function getParties(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${PARTY_LIMIT}&sort=metadata.date&props=title,slug,content,${metadataFieldsPartiesIndex}&query={
      "type":"parties",
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"},
      "metadata.show_in_main_page":true
      }`
    )
  ).then((response) => response.json());
}

function getClubParties(club) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${PARTY_LIMIT}&sort=metadata.date&props=title,slug,content,${metadataFieldsParties}&query={
      "type":"parties",
      "slug": { "$regex": "${club}", "$options": "i"},
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"}
       }`
    )
  ).then((response) => response.json()).catch(_x => "{objects: {}}".json());
}

function getCaboParties(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${PARTY_LIMIT}&sort=metadata.date&props=title,slug,content,${metadataFieldsParties}&query={
      "type":"san-jose-cabo-party",
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"},
      "metadata.show_in_main_page":true
      }`
    )
  ).then((response) => response.json());
}

function getIslaParties(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${PARTY_LIMIT}&sort=metadata.date&props=title,slug,content,${metadataFieldsParties}&query={
      "type":"isla-parties",
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"},
      "metadata.show_in_main_page":true}`
    )
  ).then((response) => response.json());
}

function getAllParties(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${ALL_PARTY_LIMIT}&sort=metadata.date&props=title,slug,content,${metadataFieldsAllParty}&query={
      "type":"parties",
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"}
      }`
    )
  ).then((response) => response.json());
}

function getAllCaboParties(pagination) {
  return fetch(
    encodeURI(
      `${CABO_BASE_URL}&limit=${ALL_PARTY_LIMIT}&sort=metadata.date&props=title,slug,content,${metadataFieldsAllParty}&query={
      "type":"san-jose-cabo-party",
      "metadata.date": {"$gte": "${PARTY_START_RANGE}"}
      }`
    )
  ).then((response) => response.json());
}

function getDjSchedule() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${DJ_SCHEDULE}&props=title,slug,content,metadata.link,metadata.date,metadata.image_url&query={
            "metadata.date": {"$gte": "${SCHEDULE_START_RANGE}"},
      "type":"autodjschedules"}`
    )
  ).then((response) => response.json());
}

function getNyeDjSchedule() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${DJ_SCHEDULE}&props=title,slug,content,metadata.main_image,metadata.link,metadata.date,metadata.start_time&query={
      "type":"nyedjschedules"}`
    )
  ).then((response) => response.json());
}

function get2024Parties() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=35&props=title,slug,content,metadata.parties.slug,metadata.parties.metadata.main_image,metadata.parties.title,metadata.parties.metadata.start_time,metadata.parties.metadata.date&query={"type":"festivals", "slug": "tulum-new-year-festival-2024" }`
    )).then((response) => response.json());
}

function getBeachClubs(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata&query={"type":"beachclubs"}`
    )
  ).then((response) => response.json());
}

function getVenues(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata&query={"type":"venues"}`
    )
  ).then((response) => response.json());
}

function getVenue(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${LIMIT}&props=title,slug,content,metadata&query={"type":"venues", "slug":"${slug}"}`
    )
  ).then((response) => response.json());
}

function getCancunClub(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${LIMIT}&props=title,slug,metadata&query={"type":"cancunclubs", "slug":"${slug}"}`
    )
  ).then((response) => response.json());
}

function getCancunClubs(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata&query={"type":"cancunclubs"}`
    )
  ).then((response) => response.json());
}

function getPDCClub(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${LIMIT}&props=title,slug,metadata&query={"type":"playadelcarmenclubs", "slug":"${slug}"}`
    )
  ).then((response) => response.json());
}

function getPDCClubs(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata&query={"type":"playadelcarmenclubs"}`
    )
  ).then((response) => response.json());
}

function getArtists(pagination) {
  let request = `${COSMIC_BASE_URL}&limit=${pagination.limit}&sort=title&skip=${pagination.start}&props=title,slug,metadata.main_image&query={"type":"artists"}`;
  let request_regex = `${COSMIC_BASE_URL}&props=title,slug,metadata.main_image&query={"type":"artists", "title": {"$regex": "${pagination.regex}", "$options": "i"}}`;
  return fetch(
    encodeURI(
      pagination.regex ? request_regex : request
    )
  ).then((response) => response.json());
}

function getTulumTV() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=1&props=title,slug,content,metadata.featured,metadata.suggestions,metadata.artist.slug,metadata.artist.metadata.videotitle,metadata.artist.metadata.videoid,metadata.artist.metadata.videocaption,&query={"type":"tulumtv"}`
    )
  ).then((response) => response.json());
}

function getArtistVideo(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=1&props=title,slug,metadata.videoid,metadata.videocaption,metadata.videotitle&query={"type":"artists","slug":"${slug}"}`
    )
  ).then((response) => response.json());
}


function getArtist(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&depth=1&props=title,slug,content,${metadataFieldsArtist}&query={"type":"artists","slug":"${slug}" }`
    )
  ).then((response) => response.json());
}

export function getArtistHighlight() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=title,slug,metadata.main_image,metadata.djschedule&query={"type":"artisthighlight","slug":"highlight" }`
    )
  )
}

function getActivities() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${LIMIT}&props=title,slug,content,metadata&query={"type":"activities"}`
    )
  ).then((response) => response.json());
}

function getArticles(pagination) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${pagination.limit}&skip=${pagination.start}&props=title,slug,content,metadata.cover,metadata.caption,created_at,published_at,metadata.summary&query={"type":"articles"}`
    )
  ).then((response) => response.json());
}

function getArticle(slug) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=title,slug,content,metadata.cover,metadata.caption,published_at,created_at,metadata.summary&query={"type":"articles",  "slug":"${slug}"}`
    )
  ).then((response) => response.json());
}

function getFaqs() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${FAQ_LIMIT}&props=title,slug,content,metadata&query={"type":"faqs"}`
    )
  ).then((response) => response.json());
}

function getFaqsByCategory(category) {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${FAQ_CLUB_LIMIT}&props=title,slug,content,metadata&query={"type":"faqs", "metadata.category": "${category}"}`
    )
  ).then((response) => response.json());
}

function getDynamicContent() {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=1&props=title,slug,content,metadata.image,metadata.link&query={"type":"home-dynamic-content"}`
    )
  ).then((response) => response.json());
}

const getBrand = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&depth=1&props=id,slug,title,content,metadata.video,metadata.description,${metadataFieldsBrand}&query={"type":"brands", "slug":"${slug}"}`
    )
  ).then((response) => response.json());
};

const getLinks = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=5&depth=1&props=id,slug,title,metadata&query={"type":"links","slug": { "$regex": "^${slug}"}}`
    )
  ).then((response) => response.status === 404 ? getLinks('tulum') : response.json());
};

const getBrands = () => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&limit=${BRANDSLIMIT}&props=slug,title,${metadataFieldsBrandIndex}&query={"type":"brands"}`
    )
  ).then((response) => response.json());
};


const getFestival = (slug) => {
  return fetch(
    encodeURI(
      `${COSMIC_BASE_URL}&props=id,slug,title,content,${metadataFieldsFestival}&query={"type":"festivals", "slug":"${slug}"}`
    )
  ).then((response) => response.json());
};


export default {
  getParty,
  getCaboParty,
  getParties,
  getMiniParties,
  getClubParties,
  getTulumTV,
  getArtistVideo,
  getAllParties,
  getAllCaboParties,
  getArtistHighlight,
  getDjSchedule,
  getNyeDjSchedule,
  get2024Parties,
  getVenues,
  getCancunClubs,
  getCancunClub,
  getPDCClub,
  getPDCClubs,
  getBeachClubs,
  getVenue,
  getArtists,
  getArtist,
  getActivities,
  getFaqs,
  getArticle,
  getArticles,
  getFaqsByCategory,
  getFestival,
  getCaboParties,
  getIslaParties,
  getIslaParty,
  getDynamicContent,
  getBrand,
  getLinks,
  getBrands,
  PARTY_LIMIT
};




